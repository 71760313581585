import { useCallback, useRef } from 'react'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Id as ToastId } from 'react-toastify'
import { toast } from 'react-toastify'
import { styled } from 'styled-components'

import { Countdown, UnstyledButton } from '@b-stock/bstock-next'
import { EmptyState, FormattedMessage } from '@b-stock/bstock-react'
import { Breakpoints, Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import LoadingAuction from '@components/features/auction/LoadingAuction'
import SearchResultCard from '@components/features/auction/SearchResultCard'
import type {
  SearchResultsPage,
  SearchResult,
} from '@queries/listingSearchQuery/types'

import { PositionedSearchPagination } from '../AuctionsLayout/shared'
import type { UpdateAuctionDataFunction } from '../shared/useAuctionUpdates'
import SortByDropdown from '../SortByDropdown'
import { StyledDropdown } from '../SortByDropdown/SortByDropdown'

type AuctionListProps = {
  openModal: () => void
  searchText: string | null
  searchResults: SearchResultsPage | null
  isMobile?: boolean
  className?: string
  isLoading?: boolean
  showSoldBy?: boolean
  headerMessage?: string // All Auctions
  updateAuctionData: UpdateAuctionDataFunction
}

export const SwitcherContainer = styled.div`
  display: flex;
  align-self: center;
  grid-gap: 0.5rem;
  justify-content: flex-end;
`

export const FiltersButton = styled(UnstyledButton)`
  justify-self: end;
  padding: 0;
  width: 2.25rem;
  height: 2.25rem;
`

export const FiltersIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`

export const ListPreamble = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`

export const SortByWrapper = styled.div`
  position: relative;
  @media ${Breakpoints.max.medium} {
    display: none;
  }
`

export const ResultCount = styled.span`
  ${Typography.Subtitle1}
  flex: 1;
  font-size: 1.25rem;
  color: ${designColors.neutral.black};
  display: block;
  align-self: center;
  @media ${Breakpoints.max.medium} {
    ${Typography.Subtitle1}
  }
`

export const GridList = styled.ol`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 3rem 0;
  gap: 3rem 1.5rem;
  @media ${Breakpoints.max.medium} {
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }
`

export const RowList = styled(GridList)`
  &&& {
    flex-wrap: nowrap;
    flex-direction: column;

    & > li {
      width: 100%;
    }
  }
`

export const SDropdown = styled(StyledDropdown)`
  width: 152px;

  @media ${Breakpoints.max.medium} {
    display: none;
  }
`

// eslint-disable-next-line @typescript-eslint/no-deprecated
export const AuctionListPagination = styled(PositionedSearchPagination)`
  @media ${Breakpoints.max.medium} {
    display: none;
  }
`

const usePollingSnackbar = () => {
  const nextManualPollUpdateRef = useRef<Date | null>(null)
  const unavailableToastId = useRef<ToastId | null>(null)
  const onManualPollScheduled = useCallback(
    (nextManualPollUpdate: Date | null) => {
      if (nextManualPollUpdate) {
        if (
          nextManualPollUpdateRef.current?.getTime() ??
          0 > nextManualPollUpdate.getTime()
        ) {
          // We've already got a notification showing for a quicker update, don't update the notification
          return
        }
        nextManualPollUpdateRef.current = nextManualPollUpdate

        const render = () => (
          <div>
            <FormattedMessage
              id="Auction.list.pusherUnavailable"
              values={{
                countdown: <Countdown target={nextManualPollUpdate} />,
              }}
            />
          </div>
        )
        if (unavailableToastId.current) {
          toast.update(unavailableToastId.current, {
            render,
          })
        } else {
          unavailableToastId.current = toast(render)
        }
      } else {
        if (unavailableToastId.current) {
          toast.dismiss(unavailableToastId.current)
        }
        unavailableToastId.current = null
      }
    },
    [unavailableToastId]
  )
  return onManualPollScheduled
}

const AuctionList = ({
  openModal,
  isMobile,
  className,
  searchText,
  searchResults,
  showSoldBy = true,
  isLoading = false,
  updateAuctionData,
}: AuctionListProps) => {
  const onManualPollScheduled = usePollingSnackbar()
  return (
    <div className={className}>
      <ListPreamble>
        <ResultCount data-testid="ResultsCount">
          {searchResults && (
            <FormattedMessage
              id="AuctionList.resultsCount"
              values={{ count: searchResults.total }}
            />
          )}
        </ResultCount>

        <SortByWrapper>
          <SortByDropdown />
        </SortByWrapper>

        <SwitcherContainer>
          {isMobile && (
            <FiltersButton onClick={openModal}>
              <FiltersIcon icon={faSlidersH} />
            </FiltersButton>
          )}
        </SwitcherContainer>
      </ListPreamble>

      {!isLoading && !searchResults?.total ? (
        <EmptyState
          icon={faSearch}
          title={
            searchText ? (
              <FormattedMessage
                id="AuctionList.noResults"
                values={{ searchText }}
              />
            ) : (
              <FormattedMessage id="AuctionList.noResultsNoSearch" />
            )
          }
        />
      ) : (
        <GridList data-testid="auction-grid-list">
          {isLoading
            ? Array.from({ length: 24 }, (_, k) => k).map((k) => (
                <li key={k}>
                  <LoadingAuction animationAllowed />
                </li>
              ))
            : null}

          {searchResults?.total
            ? searchResults.listings.map((result: SearchResult) => (
                <li key={result.listingId}>
                  <SearchResultCard
                    result={result}
                    showSoldBy={showSoldBy}
                    updateAuctionData={updateAuctionData}
                    onManualPollScheduled={onManualPollScheduled}
                  />
                </li>
              ))
            : null}
        </GridList>
      )}
    </div>
  )
}

export default AuctionList
