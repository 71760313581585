// import DistanceFilter from './filters/DistanceFilter'
import AccessoriesFilter from './filters/AccessoriesFilter'
import BusinessUnitFilter from './filters/BusinessUnitFilter'
import { CategoryTreeFilter } from './filters/CategoryFilter'
// import SellerFilter from './filters/SellerFilter'
import ConditionFilter from './filters/ConditionFilter'
import CosmeticConditionFilter from './filters/CosmeticConditionFilter'
// import MobileGradeFilter from './filters/MobileGradeFilter'
import CurrentBidFilter from './filters/CurrentBidFilter'
import FunctionalityFilter from './filters/Functionality'
import InventoryTypeFilter from './filters/InventoryTypeFilter'
import ManufacturerFilter from './filters/ManufacturerFilter'
import NumberOfUnitsFilter from './filters/NumberOfUnitsFilter'
import PackagingConditionFilter from './filters/PackagingConditionFilter'
import PricingStrategyFilter from './filters/PricingStrategyFilter'
import ProductTypeFilter from './filters/ProductTypeFilter'
import RetailValueFilter from './filters/RetailValueFilter'
import SellerOtherFilter from './filters/SellerOtherFilter'
import ShipmentTypeFilter from './filters/ShipmentTypeFilter'
import WarehouseLocationFilter from './filters/WarehouseLocationFilter'
import type { AuctionFilterList } from './types'

// TODO: implement distance filter

// Note: Several "Rating" filters were previously implemented, but the code has since been
//       deleted. Some of this old code might be useful if/when we resurrect those filters.
//       The MR that removed the code can be found here:
//       https://gitlab.bstock.io/b-stock/code/three-mp/fe/buyer-portal/-/merge_requests/715

export function getAllListingsFilters(): AuctionFilterList {
  return [
    // DistanceFilter,
    CategoryTreeFilter,
    //RatingFilter,
    //SellerFilter,
    ConditionFilter,
    PackagingConditionFilter,
    CosmeticConditionFilter,
    InventoryTypeFilter,
    // MobileGradeFilter,
    ManufacturerFilter,
    FunctionalityFilter,
    AccessoriesFilter,
    ShipmentTypeFilter,
    PricingStrategyFilter,
    WarehouseLocationFilter,
    RetailValueFilter,
    NumberOfUnitsFilter,
    CurrentBidFilter,
  ]
}

export function getSellerPageFilters(): AuctionFilterList {
  return [
    // DistanceFilter,
    CategoryTreeFilter,
    ConditionFilter,
    PackagingConditionFilter,
    CosmeticConditionFilter,
    InventoryTypeFilter,
    // MobileGradeFilter,
    ManufacturerFilter,
    FunctionalityFilter,
    AccessoriesFilter,
    ProductTypeFilter,
    BusinessUnitFilter,
    ShipmentTypeFilter,
    PricingStrategyFilter,
    WarehouseLocationFilter,
    RetailValueFilter,
    NumberOfUnitsFilter,
    CurrentBidFilter,
  ]
}

export function getAllSellersFilters(): AuctionFilterList {
  return [
    // DistanceFilter,
    CategoryTreeFilter,
    PackagingConditionFilter,
    InventoryTypeFilter,
    //RatingFilter,
    //SellerFilter,
    NumberOfUnitsFilter,
    SellerOtherFilter,
  ]
}
