import { useIntl } from 'react-intl'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const BusinessUnitFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData)
  const availableItems = useFilterOptions(availableItemsData)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.businessUnit',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

BusinessUnitFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters['Business Unit'] || {},
    availableItems: data?.availableFilters['Business Unit'] || {},
    value: new Set(state.filters.businessUnit),
    onChange: (value: Set<string>) =>
      updateFilters({ businessUnit: [...value] }),
  }
}

BusinessUnitFilter.label = 'Filters.businessUnit'

BusinessUnitFilter.Applied = createCategoricalAppliedComponent(
  'businessUnit',
  BusinessUnitFilter.label,
  (businessUnit: string) => <span>{businessUnit}</span>
)

export default BusinessUnitFilter
