import { useState, useCallback } from 'react'

import { styled, css } from 'styled-components'

import { FormattedMessage } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

import { useAuctionSearch } from '@components/AuctionSearchProvider'
import { AppliedFilters } from '@components/features/auction/AppliedFilters'
import AuctionList from '@components/features/auction/AuctionList'
import { SearchBar } from '@components/layouts/StandardLayout/Header/SearchBar/SearchBar'
import { SearchBarContainer } from '@components/layouts/StandardLayout/Header/ui'
import SearchFilters from '@components/SearchFilters'
import type { AuctionFilterList } from '@components/SearchFilters'
import MobileSearchFilters from '@components/SearchFilters/MobileSearchFilters'
import SearchPagination from '@components/SearchPagination'
import SpinnerWrapper from '@components/SpinnerWrapper'
import { useIsMobile } from '@hooks/layout'

import SortByDropdown from '../SortByDropdown/SortByDropdown'

interface IProps {
  filters: AuctionFilterList
  headerMessage?: string // defaults to AuctionList.allAuctions === 'All Auctions'
  showSoldBy?: boolean
  showSearch: boolean
  searchText: string
}

type SearchLayoutProps = {
  $isMobile: boolean
}

const MobileDropdownContainer = styled.div`
  padding: 0.5rem 0 1rem 0;
`
const CommonLayout = styled.div<SearchLayoutProps>`
  display: grid;
  grid-template-columns: 11.5rem 1fr;
  grid-template-rows: auto 1fr auto;
  grid-column-gap: 1.5rem;
  grid-template-areas:
    'filters-select applied'
    'filters-select auctions'
    'filters-select pagination';

  ${(o) =>
    o.$isMobile &&
    css`
      grid-template-areas:
        'applied'
        'filters-select-modal'
        'filters-select'
        'auctions'
        'pagination';
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    `}
`
export const SearchLayout = styled(CommonLayout)<SearchLayoutProps>`
  padding-top: 45px;
  margin-bottom: 24px;
`

export const PositionedDesktopSearchFilters = styled(SearchFilters)`
  grid-area: filters-select;
` as typeof SearchFilters // Ensure that the wrapped component remains generic

// TODO: Eventually, this should be replaced by `bstock-react`'s Pagination
// eslint-disable-next-line @typescript-eslint/no-deprecated
const PositionedSearchPagination = styled(SearchPagination)`
  grid-area: pagination;
`

export const PositionedMobileSearchFiltersContainer = styled.div`
  grid-area: filters-select-modal;
`

export const PositionedAuctionList = styled(AuctionList)`
  grid-area: auctions;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`

export const StyledSearchFilters = styled(SearchFilters)``

export const SModalContent = styled.div`
  height: fit-content;
  overflow-x: auto;
  padding: 1rem;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const AllAuctionsHeader = styled.h1`
  margin-bottom: 1rem;
  margin-top: 0;
  padding-bottom: 2rem;
  color: ${designColors.neutral.black};
`
const HeaderLayout = styled(CommonLayout)<SearchLayoutProps>`
  align-items: start;
`

const SearchContainer = styled(SearchBarContainer)`
  padding: 0;
`
const CustomSearchBar = styled(SearchBar)`
  max-width: 100%;
`

export const AuctionLayout = ({
  filters,
  showSearch,
  showSoldBy = true,
  searchText,
  headerMessage = 'AuctionList.allAuctions',
}: IProps) => {
  const isMobile = useIsMobile()

  // Actual loading of data
  const searchCtx = useAuctionSearch()
  const {
    data: searchResults,
    isLoading,
    state: { query },
  } = searchCtx
  const [showFilterModal, setShowFilterModal] = useState(false)
  const openFilterModal = useCallback(() => setShowFilterModal(true), [])
  const closeFilterModal = useCallback(() => setShowFilterModal(false), [])

  const onSearch = (query: string) => {
    searchCtx.setQuery(query)
  }
  const shouldRenderMobileFilters = isMobile && showFilterModal
  const shouldRenderDesktopFilters = !isMobile

  return (
    <>
      <HeaderLayout $isMobile={isMobile}>
        <AllAuctionsHeader>
          <FormattedMessage id={headerMessage} />
        </AllAuctionsHeader>

        {showSearch ? (
          <SearchContainer>
            <CustomSearchBar placeholder={searchText} onSubmit={onSearch} />
          </SearchContainer>
        ) : null}
      </HeaderLayout>

      <SearchLayout $isMobile={isMobile}>
        {shouldRenderMobileFilters ? (
          <MobileSearchFilters
            onSubmit={closeFilterModal}
            closeModal={closeFilterModal}
            filters={filters}
            searchCtx={searchCtx}
            sortComp={
              <MobileDropdownContainer>
                <SortByDropdown />
              </MobileDropdownContainer>
            }
          />
        ) : null}

        <AppliedFilters filters={filters} />

        {shouldRenderDesktopFilters ? (
          <PositionedDesktopSearchFilters
            filters={filters}
            searchCtx={searchCtx}
          />
        ) : null}

        <SpinnerWrapper showSpinner={!searchResults}>
          {searchResults ? (
            <PositionedAuctionList
              headerMessage={headerMessage}
              openModal={openFilterModal}
              searchResults={searchResults}
              isLoading={isLoading}
              isMobile={isMobile}
              searchText={query}
              showSoldBy={showSoldBy}
              updateAuctionData={searchCtx.updateAuctionData}
            />
          ) : null}
        </SpinnerWrapper>

        {searchResults?.total ? <PositionedSearchPagination /> : null}
      </SearchLayout>
    </>
  )
}
