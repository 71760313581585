import { createNumericRangeAppliedComponent } from '../../shared/filterUtils'
import type { Range, LeftClosedRange } from '../../shared/NumericRangeFilter'
import NumericRangeFilter, {
  IntegerFormat,
} from '../../shared/NumericRangeFilter'
import type { AuctionFilter } from '../../types'

type ValueType = Range

const ranges: LeftClosedRange[] = [
  [0, 100],
  [100, 1000],
  [1000, null],
]

const NumberOfUnitsFilter: AuctionFilter<ValueType> = ({
  onChange,
  value,
}: {
  onChange: (value: Range) => void
  value: Range
}) => (
  <NumericRangeFilter
    items={ranges}
    selected={value}
    onChange={onChange}
    formatRange={IntegerFormat}
  />
)

NumberOfUnitsFilter.getOwnProps = ({ state, updateFilters }) => ({
  onChange: (numberOfUnitsRanges: ValueType) => {
    updateFilters({ numberOfUnitsRanges })
  },
  value: state.filters.numberOfUnitsRanges,
})

NumberOfUnitsFilter.label = 'Filters.numberOfUnits'

NumberOfUnitsFilter.Applied = createNumericRangeAppliedComponent(
  'numberOfUnitsRanges',
  NumberOfUnitsFilter.label
)

export default NumberOfUnitsFilter
